<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>当前上传/编辑的文件名称为：“{{
        currentFile.name 
      }}”</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="文件上传/编辑" icon="ios-contacts">
            <div class="des-wrapper">
              <p class="des-p">因以下标准的相关条目，需上传此文件资料：</p>
              <p v-for="(item,index) in JSON.parse(currentFile.related_clause)" :key="index" class="des-p2">
                <span>{{index+1}}、</span>
                <span>{{item.standard_no}}</span>
                <span>《{{item.standard_name}}》</span>
                <span>{{item.clause_no}}</span>：
                <span>{{item.content}}</span>
              </p>
            </div>
            <div class="upload-outer-wrapper">
              <p class="title">
                文件上传(点击下方加号添加文件)：
              </p>
            <div class="upload-wrapper">
              <div class="upload-item" v-for="(item,index) in uploadList" :key="index">
                <template v-if="item.status === 'finished'">
                    <img :src="judgeIconForFile(item.fileType)">
                    <div class="upload-list-cover">
                      <p title="文件名" class="name-p"><span>{{item.name}}</span></p>
                      <p title="文件大小" class="size-p"><span>{{formatSize(item.size)}}</span></p>
                      <div class="icon-wrapper">
                        <span class="icon-i down-icon"><Icon type="ios-download" @click.native="handleDownload(item)" :size="24"></Icon></span>
                        <span class="icon-i del-icon"><Icon type="ios-trash" @click.native="handleRemove(item)" :size="24"></Icon></span>
                      </div>
                    </div>
                  </template>
                <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" ></Progress>
                </template>
              </div>
              <Upload
                ref="upload"
                :show-upload-list="false"
                :on-success="handleSuccess"
                :before-upload="handleBeforeUpload"
                :on-progress="handleProgress"
                multiple
                type="drag"
                :action="url"
                :data="dataForUpload"
                class="upload-i">
                <div class="upload-icon">
                    <Icon type="ios-add" size="40"></Icon>
                </div>
              </Upload>
            </div>
            <div class="button-wrapper">
              <Button
                @click="confirmSubmit"
                type="primary"
                size="large"
                >提交</Button
              >
              <Button
                size="large"
                @click="dropFile"
                class="cancel-button"
                >放弃</Button
              >
            </div>
            </div>
          </TabPane>
          <TabPane label="上传记录" icon="ios-contacts">
            <normal-list
              :columnsFormatData="columnsFormatData"
              askData="myUploadRecordList"
              :refreshData="update_count"
              main_width="1260px"
              :dataId="fileId"
            ></normal-list>
          </TabPane>
        </Tabs>
      </div>
      
    </div>
    <Modal
        v-model="modalSwicth.confirmFile"
        :width="1280"
        :mask-closable="false"
        @on-cancel="closeModal('confirmFile')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>请确认文件名是否需要修改：</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('confirmFile')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <p v-for="(item,index) in uploadList" :key="index">
              <span>文件{{index+1}}：</span>
              <input type="text" v-model="item.pureName" class="name-input"/>
              <span>.{{item.fileType}}</span>
            </p>
          </div>
        </div>
        <div slot="footer">
          <Button
            @click="handleSubmit"
            :loading="loadingStatus"
            size="large"
            type="primary"
            >{{ loadingStatus ? "Uploading" : "确认提交" }}</Button
          >
          <Button @click="handleReset" size="large" style="margin-left: 8px"
            >重置</Button
          >
          <Button
            size="large"
            @click="closeModal('confirmFile')"
            class="cancel-button"
            >取消</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.viewFile"
        :width="1280"
        :mask-closable="false"
        @on-cancel="closeModal('viewFile')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>文件列表：</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('viewFile')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="form-wrapper">
            <Table :loading="viewloading" border :columns="columnsFileView" :data="currentFileOfRecord" no-data-text="无文件"></Table>
          </div>
        </div>
        <div slot="footer">
          <Button
            size="large"
            @click="closeModal('viewFile')"
            class="cancel-button"
            >关闭</Button
          >
        </div>
      </Modal>
      <Modal
        v-model="modalSwicth.viewConfirm"
        :width="800"
        :mask-closable="false"
        @on-cancel="closeModal('viewConfirm')"
      >
        <p slot="header" class="sc-header">
          <Icon type="ios-list-box" />
          <span>确认详情：</span>
        </p>
        <i
          class="ivu-icon ivu-icon-ios-close"
          slot="close"
          @click="closeModal('viewConfirm')"
        ></i>
        <div class="sc-content-wrapper">
          <div class="major-wrapper">
            <p>该文件需以下专业人员进行确认：</p>
            <p class="major-p"><span v-for="(item,index) in currentShouldMajor" :key="index">{{index+1}}、{{item.name}}；</span></p>
          </div>
          <div class="form-wrapper">
            <Table :loading="viewloading" border :columns="columnsConfirmView" :data="currentConfirmOfRecord" no-data-text="暂无内容"></Table>
          </div>
        </div>
        <div slot="footer">
          <Button
            size="large"
            @click="closeModal('viewConfirm')"
            class="cancel-button"
            >关闭</Button
          >
        </div>
      </Modal>
  </div>
</template>
<script>
import normalList from "@/components/Common/NormalList.vue";

let userToken = localStorage.getItem("current_user_token");
let authData = JSON.parse(localStorage.getItem("authData"));

export default {
  name: "fileEdit",
  components: {
    normalList,
  },
  props: {
    fileId: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      update_count: 1,
      currentFile: {
        id:0,
        related_clause: JSON.stringify([]),
        name: "",
      },
      url:this.$url + "/file_upload",
      dataForUpload:{"userToken":userToken,fileId:0,pageId:"",},
      recordId:0,
      uploadList:[],
      columnsFormatData: [
        {
          title: "上传人",
          width: 200,
          align: "center",
          key: "upload_name",
        },
        {
          title: "上传时间",
          width: 180,
          align: "center",
          key: "op_time",
        },
        {
          title: "审核人",
          width: 200,
          align: "center",
          key: "verify_name",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.verify_name?p.row.verify_name:'-'
            );
          }
        },
        {
          title: "审核时间",
          width: 180,
          align: "center",
          key: "verify_time",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.verify_time?p.row.verify_time:'-'
            );
          }
        },
        {
          title: "确认进度",
          width: 95,
          align: "center",
          key: "confirm_progress",
          render: (h, p) => {
            let totalMajor = 0;
            if(p.row.major){
              totalMajor = JSON.parse(p.row.major).length;
            }
            return h(
              "p",
              {
              },
              p.row.confirmed+'/'+totalMajor
            );
          }
        },
        {
          title: "状态",
          key: "stat",
          align: "center",
          width:100
        },
        {
          title: "操作",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "blue" },
                  on: {
                    click: () => {
                      this.modalSwicth.viewFile = true;
                      this.getViewFile(params.row);
                    },
                  },
                },
                "查看文件"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "green" },
                  on: {
                    click: () => {
                      if(params.row.major){
                        this.currentShouldMajor = JSON.parse(params.row.major);
                      }
                      this.modalSwicth.viewConfirm = true;
                      this.getViewConfirm(params.row);
                    },
                  },
                },
                "查看确认详情"
              ),
            ]);
          },
        },
      ],
      columnsFileView:[
        {
          title: "文件名",
          align: "center",
          key: "name",
        },
        {
          title: "大小",
          width: 100,
          align: "center",
          key: "file_size",
        },
        {
          title: "类型",
          width: 90,
          align: "center",
          key: "file_type",
        },
        {
          title: "上传时间",
          width: 180,
          align: "center",
          key: "upload_time",
        },
        {
          title: "上传人",
          width: 200,
          align: "center",
          key: "upload_name",
          render: (h, p) => {
            return h(
              "p",
              {
              },
              p.row.upload_name?p.row.upload_name:'-'
            );
          }
        },
        {
          title: "操作",
          align: "center",
          width: 90,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { marginRight: "10px", color: "blue" },
                  on: {
                    click: () => {
                      this.handleDownload(params.row);
                    },
                  },
                },
                "下载"
              ),
            ]);
          },
        },
      ],
      columnsConfirmView:[
        {
          title: "确认人",
          align: "center",
          key: "confirm_name",
        },
        {
          title: "确认时间",
          align: "center",
          key: "op_time",
        },
        {
          title: "专业",
          align: "center",
          key: "major",
        },
      ],
      modalSwicth: {
        confirmFile: false,
        viewFile:false,
        viewConfirm:false,
      },
      loadingStatus: false,
      viewloading:false,
      currentFileOfRecord:[],
      currentConfirmOfRecord:[],
      currentShouldMajor:[]
    };
  },
  watch: {},
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "targetFileInfo");
      form.append("file_id", this.fileId);
      this.loadingStatus = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          this.loadingStatus = false;
          if (response.data.stat === "ok") {
            this.currentFile = response.data.res_record;
            if(response.data.fileList.length>0){
              for(let i of response.data.fileList){
              this.uploadList.push({
                fileType: i.file_type,
                file_url: i.file_url,
                id: i.id,
                name: i.name,
                pureName : this.getPureName(i.name),
                percentage: 100,
                showProgress: false,
                size: i.file_size,
                status: "finished",
                uploadTime:i.upload_time
              });
            }
            this.recordId = response.data.fileList[0].record_id;
            }
            this.dataForUpload.fileId = this.currentFile.id;
            this.dataForUpload.pageId = response.data.uuid;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    getViewFile(record) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "fileOfRecord");
      form.append("recordId", record.id);
      this.viewloading = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          this.viewloading = false;
          if (response.data.stat === "ok") {
            this.currentFileOfRecord = response.data.res_record;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    getViewConfirm(record) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "confirmOfRecord");
      form.append("recordId", record.id);
      this.viewloading = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          this.viewloading = false;
          if (response.data.stat === "ok") {
            this.currentConfirmOfRecord = response.data.res_record;
          } else {
            this.$Message.error(response.data.errorInfo);
          }
        })
        .catch((error) => {
          this.$Message.error(error + "未知错误！");
        });
    },
    handleBeforeUpload(file){
      let item = {};
      item.size = file.size;
      item.name = file.name;
      item.pureName = this.getPureName(file.name);
      item.showProgress = true;
      item.percentage = 0;
      this.uploadList.push(item);
      return true;
    },
    handleProgress(event,file){
      let item = this.uploadList.find(val=>
        val.name===file.name&&val.size===file.size);
        item.percentage = parseFloat(event.percent.toFixed(2));
    },
    handleSuccess(res,file){
      let item = this.uploadList.find(val=>
        val.name===file.name&&val.size===file.size);
      item.percentage = 100;
      item.showProgress = false;
      item.size = file.size;
      item.status = "finished";
      item.id = res.the_file.id;
      item.file_url = res.the_file.file_url;
      item.fileType = res.the_file.file_type;
    },
    judgeIconForFile(item){
      console.log(item);
      return null;
    },
    formatSize(size){
      if(parseInt(size)>1024*1024){
        return (size/(1024*1024)).toFixed(3)+'MB';
      }else{
        return Math.round(size/1024)+'KB';
      }
    },
    handleRemove(item){
      this.confirmBox(item);
    },
    handleDownload(item){
      window.open(this.$fileUrlFac(item.file_url,authData.sfileRight),'_blank');
    },
    openModal(value) {
      this.modalSwicth[value] = true;
    },
    closeModal(value) {
      this.modalSwicth[value] = false;
    },
    // getStaticOption(){
    //   this.$axios
    //       .post(this.$url + "/ask_static_options", {
    //         userToken: userToken,
    //         askData:'ClauseManage'
    //       })
    //       .then(response => {
    //         if (response.data.stat == "ok") {
    //           this.ClauseOptions = response.data.res_record;
    //         } else {
    //           this.$Massage.error("获取选项数据失败，网络存在问题！");
    //         }
    //       });
    // },
    uploadSet(){
      this.modalSwicth.uploadSw = true;
    },
    verifySet(){
      this.modalSwicth.verifySw = true;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content:
          "<p>" +
          "文件：" +
          value.name +
          "</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {},
      });
    },
    getPureName(name){
      let tmp = name.split('.');
      if(tmp.length>1){
        tmp.pop();
      }
      return tmp.join('.');
    },
    recordFormatDel(id) {
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", "delTheFile");
      form.append("id", id);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            let index = this.uploadList.findIndex(val=>val.id===id);
            this.uploadList.splice(index,1);
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    confirmSubmit(){
      if(this.uploadList.length>0){
        this.modalSwicth.confirmFile = true;
      }else{
        this.$Message.warning("请先添加文件至文件上传列表里！");
      }
    },
    dropFile(){

    },
    handleReset() {
      for (let item of this.uploadList) {
        item.pureName = this.getPureName(item.name);
      }
    },
    handleSubmit() {
      for(let i of this.uploadList){
        if(!(i.pureName.replace(/\s+/g,""))){
          this.$Message.warning("存在为空字符的文件名！");
          return false;
        }else{
          i.pureName = i.pureName.replace(/\s+/g,"");
        }
      }
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", 'recordUpload');
      form.append("fileList", JSON.stringify(this.uploadList));
      form.append("pageId", this.dataForUpload.pageId);
      form.append("recordId", this.recordId);
      form.append("fileId", this.dataForUpload.fileId);
      this.loadingStatus = true;
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.$axios
        .post(this.$url + "/file_manage", form, config)
        .then((response) => {
          this.loadingStatus = false;
          if (response.data.stat === "ok") {
            this.$Message.success("操作成功！");
            setTimeout(()=>{ window.close();},500)
           
          } else {
            this.$Message.error(response.data);
          }
        })
        .catch((error) => {
          this.loadingStatus = false;
          this.$Message.error(error + "未知错误！");
        });
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.des-wrapper{
  padding: 20px 100px;
}


.des-p{
  font-size: 14px;
  color:#666;
  line-height: 3;
}

.des-p2{
  font-size: 16px;
  color:#111;
  text-indent: 32px;
  line-height: 2;
}
.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 30px 50px 30px 50px;
}

.export-button {
  position: relative;
  bottom: 48px;
  left: 11px;
  margin-left:10px;
}

.upload-wrapper{
  margin-top:20px;
  padding: 10px;
  width:100%;
  display: flex;
  flex-wrap: wrap;
  border:1px solid #999;
  border-radius: 5px;
}

.upload-outer-wrapper{
  margin:100px auto 0;
  width:1072px;
}

.title{
  font-size: 14px;
  font-weight: 600;
  color:#666;
}

.icon-i{
  cursor: pointer;
}

.upload-item{
  height:300px;
  width:200px;
  border:1px dashed #999;
  padding: 10px;
  margin:5px;
  position: relative;
}

.upload-i{
  display: inline-block;
  width:200px;
  margin:5px;
}

.upload-icon{
  height:300px;
  line-height: 300px;
  width:200px;
}

.down-icon{
  position: absolute;
  bottom:15px;
  left:10px;
}

.del-icon{
  position: absolute;
  bottom:15px;
  right:10px;
}

.icon-i:hover{
  color:rgb(2, 109, 170);
}

.upload-list-cover{
  text-align: center;
}

.size-p{
  position: absolute;
  bottom:75px;
  width:100%;
  text-align: center;
  margin-top:15px;
}

.name-p{
  text-align: left;
  display: inline-block;
}
.upload-list-cover{
  word-break: break-all;
}

.button-wrapper{
  text-align: center;
  padding: 30px;
}

.button-wrapper button{
  margin:0 15px;
}
.name-input{
  padding: 5px 10px;
  width:85%;
  margin:5px;
  margin-left:30px;
}

.major-wrapper{
  text-align: center;
}

.major-p{
  font-weight: 600;
  line-height: 2.5;
}

</style>